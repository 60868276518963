import { Location, useLocation } from 'react-router';
import { useCallbackRef } from '@synoptic/ui-kit/utils/use-callback-ref.js';
import { useEffect, useState } from 'react';
import { GridStateSnapshot, StateSnapshot } from 'react-virtuoso';

type FeedPositions =
  | Map<string, GridStateSnapshot>
  | Map<string, StateSnapshot>;

const defaultGetKey = (location: Location) => location.key;

export interface RestoreFeedBaseProps {
  getKey?: (location: Location) => string;
  onRestore?: (args: { fetchOptions: Partial<Request> }) => void;
}

export const useRestoreFeedBase = ({
  feedPositions,
  getKey = defaultGetKey,
  onRestore,
}: RestoreFeedBaseProps & { feedPositions: FeedPositions }) => {
  const loc = useLocation();
  const [mounted, setMounted] = useState(false);

  const key = getKey(loc);

  const [restoreStateFrom] = useState(() => feedPositions.get(key));

  const refresh = useCallbackRef(onRestore);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (feedPositions.get(key)) {
      const abortController = new AbortController();

      refresh({ fetchOptions: { signal: abortController.signal } });

      return () => {
        abortController.abort();
      };
    }
  }, [key, refresh, feedPositions]);

  return {
    restoreStateFrom,
    key,

    /** pass this to feed container to avoid flash of unscrolled content https://github.com/petyosi/react-virtuoso/issues/957#issuecomment-1653179355 */
    style:
      restoreStateFrom && !mounted
        ? { minHeight: restoreStateFrom.scrollTop }
        : undefined,
  };
};
